import React from "react";
import styled from "styled-components";
import { blue400 } from "ui/colors";

import {
    LayoutRoot,
    TitleBox,
    ContentContainer,
    ContentBox,
} from "auth/components/UnauthenticatedLayout";
import PoweredByCC from "auth/components/PoweredByCC";
import itiLogo from "iti/images/itiLogo.svg";

const AppLoading = (props) => (
    <LayoutRoot>
        <Logo src={itiLogo} />
        <noscript>
            <style>
                {`noscript + * {
                        display: none;
                    }`}
            </style>
            <TitleBox>
                <h2>JavaScript disabled</h2>
            </TitleBox>
            <ContentContainer>
                <ContentBox>
                    <p>
                        ClearCalcs uses modern web features that requires
                        JavaScript to be enabled in your browser. Please enable
                        JavaScript and refresh the page to try again.
                    </p>
                    <p>
                        If you need help with this process{" "}
                        <a
                            href="https://www.enable-javascript.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>please click here.</span>
                        </a>
                    </p>
                </ContentBox>
            </ContentContainer>
        </noscript>
        <TitleBox>
            <h2>Loading...</h2>
        </TitleBox>
        <PoweredBy>
            <PoweredByCC />
        </PoweredBy>
    </LayoutRoot>
);

export default AppLoading;

const Logo = styled.img`
    max-width: 300px;
    margin: 0 auto;
    display: block;
    margin-top: 3em;
    margin-bottom: 1em;
`;

const PoweredBy = styled.div`
    color: ${blue400};
    max-width: 250px;
    margin: 0 auto;
`;
