import React, { useContext } from "react";
import { useRouter } from "next/router";
import { SessionStoreContext } from "framework/SessionStoreContext";

export default function OrganisationsController() {
    const router = useRouter();
    const sessionStore = useContext(SessionStoreContext);
    const organisation = sessionStore.user!.relationships.primaryOrganisation;

    if (!organisation) {
        return (
            <div>
                You are not in any organisations. Ask your organisation&apos;s
                ITI Design Spec administrator to invite you, or contact ITI
                support.
            </div>
        );
    } else {
        router.replace(`/organisation/${organisation.id}`);
    }

    return null;
}
